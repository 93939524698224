<template>
    <div class="sPickerContent">
        <ul :id="listId" class="sPiker" @scroll="scrollPicker">
            <li></li>
            <li :class="{'pickerOn':index==listOn}" :style="pickerStyle" v-for="(picker,index) in pickerList"
                :key="index">
                {{picker[pickerVal]}}
            </li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'sPiker',
        props: [
            'val',//数据文字取值
            'keys',//数据key取值
            'pickerList',//picker数据源
        ],
        created() {
            this.pickerVal = this.val || 'val';
            this.pickerKey = this.keys || 'keys';
        },
        data() {
            return {
                listOn: 0,
                listId: '',
                oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
                scrollTop: 0,
                timer: null,
                hei: 0,
                animationTimer: null,
                pickerVal: '',
                pickerKey: '',
                pickerStyle: {}
            }
        },
        watch: {
            'scrollTop': function () {
                let _this = this;
                _this.oldScrollTop = _this.scrollTop;
                if (_this.timer) {
                    clearTimeout(_this.timer);
                }
                _this.timer = setTimeout(() => {
                    if (_this.scrollTop == _this.oldScrollTop) { //延时执行后当newValue等于window.scrollY，代表滚动结束
                        let dom = document.getElementById(_this.listId);
                        // console.log(dom.childNodes)
                        let piOn = 0;
                        for (let i = 0; i < dom.childNodes.length; i++) {
                            if (dom.childNodes[i].className == 'pickerOn') {
                                piOn = i - 1;
                            }
                        }
                        // console.log(piOn)
                        let top = dom.scrollTop;
                        let index = parseInt(top / _this.hei);
                        let scrollIndex = index;
                        _this.listOn = index;
                        if (top % _this.hei) {
                            _this.listOn += 1;
                            scrollIndex += 1;
                        }
                        _this.animations(dom, _this.hei * scrollIndex);
                        _this.$emit('changePicker', _this.pickerList[piOn]);
                    }
                }, 50);
            }
        },
        mounted() {
            let math = Math.random().toString().replace('0.', '');
            let date = new Date().getTime();
            this.listId = 'sPiker' + math + date;
            this.$emit('changePicker', this.pickerList[0]);
        },
        methods: {
            scrollPicker(e) {
                let dom = document.getElementById(this.listId);
                if (!this.hei) {
                    this.hei = dom.children[0].clientHeight;
                    this.pickerStyle = {
                        height: this.hei + 'px'
                    }
                }
                let top = dom.scrollTop;
                let index = parseInt(top / this.hei);
                if (index >= (this.pickerList.length - 1)) {
                    this.listOn = index;
                    return
                }
                this.scrollTop = e.target.scrollTop;
            },
            animations(dom, scroll) {
                let old = dom.scrollTop;
                let count = parseInt(scroll - old);
                if (this.animationTimer) {
                    clearInterval(this.animationTimer);
                }
                let _this = this
                this.animationTimer = setInterval(function () {
                    if (old > scroll) {
                        old -= 1;
                    } else if (old < scroll) {
                        old += 1;
                    } else if (!count) {
                        clearInterval(_this.animationTimer);
                    }
                    count -= 1;
                    dom.scrollTop = old;
                }, 1);
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .sPickerContent {
        height: 2rem;
        overflow-y: hidden;
    }

    .sPiker {
        transform: translate3d(0, 0, 0) rotateX(0);
        height: 2rem;
        overflow-y: scroll;
        width: 100%;
        li {
            height: .4rem;
            line-height: .4rem;
        }
    }

    .pickerOn {
        border-top: 1px solid #F5F5F5;
        border-bottom: 1px solid #F5F5F5;
    }
</style>